.Manager {
    &__Header {
        display: flex;
        padding: 0 20px;
        justify-content: space-between;
        align-items: center;
    }

    &__Logo {
        display: flex;
        margin-right: 15px;
    }

    &__LoggedIn {
        padding: 0 20px;
        display: flex;
        flex-direction: column;
    }

    &__Filters {
        display: flex;
    }

    &__DateRange {
        position: absolute;
        z-index: 101;
    }

    &__Filter {
        margin-right: 15px;
        position: relative;

        .MuiFormControl-root {
            min-width: 200px;
        }

        .MuiFormControl-marginNormal {
            margin-top: 0;
        }

        #date-picker-dialog {
            opacity: 0;
            transition: 0.4s ease opacity;
        }

        &.show {
            #date-picker-dialog {
                opacity: 1;
            }
        }

        &-clear {
            opacity: 0;
            transition: 0.4s ease opacity;
            z-index: 102;

            &.show {
                opacity: 1;
                margin-left: 15px;
                margin-top: 10px;
            }
        }
    }

    &__Table {
        margin-top: 20px;
        min-height: 500px;
        flex: 1;

        .Mui-even {
            background-color: rgb(236, 236, 236);
        }
    }

    &__Actions {
        align-self: flex-end;
        margin-bottom: 10px !important;
    }

    &__Form {
        display: flex;
        flex-direction: column;

        .MuiTextField-root {
            margin-bottom: 20px;
        }
    }

    &__MFA {
        &__Continue {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
    }
}
