:root {
    --amplify-primary-color: #{$base};
    --amplify-primary-tint: #{$tint};
    --amplify-primary-shade: #{$secondary};
}

amplify-authenticator {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

amplify-sign-in {
    display: flex;
    align-items: center;
    height: calc(100vh - 77px);
}
