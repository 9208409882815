.Manager {
    &__AddSubmission,
    &__Delete {
        display: flex;
        justify-content: center;
        align-items: center;

        &-modal {
            background: $white;
            padding: 20px;
        }
    }
}
